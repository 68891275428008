import React, { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useRemark } from "react-remark";

import PageLayout from "../components/PageLayout";
import Seo from "../components/Seo";
import AboutPageData from "../../content/about.json";

const AboutPage = () => {
  const { about_content } = AboutPageData;
  const [markdownContent, setMarkdownSource] = useRemark();

  useEffect(() => {
    setMarkdownSource(about_content);
  }, []);
  return (
    <PageLayout>
      <Seo title="About" slug="/about" />
      <div className="about">
        <StaticImage
          src="../images/about_cover.png"
          alt="Exterior of Papa Monkey Shop in Celina, OH"
          placeholder="blurred"
          layout="fullWidth"
          fit="cover"
          aspectRatio={2.4}
        />
        <section className="about__main-wrapper">
          <div className="about__main">{markdownContent}</div>
        </section>
      </div>
    </PageLayout>
  );
};

export default AboutPage;
